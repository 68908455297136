<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t(title) }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">{{ $t("dispensing.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a> {{ $t("dispensing.dispensing") }} </a>
                </li>
                <li class="breadcrumb-item" v-if="orderType == 2">
                  {{ $t("dispensing.intrahospital") }}
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 text-right">
            <button
              class="btn btn-outline-success mr-2 mb-2"
              v-if="
                showAction(1) &&
                withPermissionName(permissions.DIS_SURVEYS_DEMO)
              "
              @click="clicQuiz()"
            >
              <i class="fa fa-question-circle mr-2"></i>
              {{ $t("global.survey") }}
            </button>
            <button
              class="btn btn-outline-success mr-2 mb-2"
              v-if="
                showAction(1) && withPermissionName(permissions.DIS_PRE_ADD_01)
              "
              @click="changeAction(2)"
            >
              <i class="ti-plus mr-2"></i> {{ $t("global.create_dispensing") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div v-bind:class="[action != 1 ? 'card border-none-b' : 'card']">
              <AlertMessageComponent ref="alertForm" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-file-plus mr-2"></i
                      >{{ titleAction }}
                    </h5>
                  </div>
                  <div class="col-md-2" v-if="orderType == 1">
                    <p
                      class="pt-3"
                      v-html="
                        $tc(
                          'dispensing.message_quiz',
                          totalSurveys - surveyCount,
                          { n: totalSurveys - surveyCount }
                        )
                      "
                    ></p>
                  </div>
                </div>
              </div>
              <DispensingTableComponent
                v-if="showTable"
                v-show="showAction(1)"
                :headers="headers"
                :modelTable="listDispensing"
                :pagesAtributes="pagesAtributes"
                :methodPage="findAllPrescriptionByBranchOffice"
                :allIdBranchOffice="branchOffice.idBranchoffice"
                :isButton="true"
                :isUpdate="withPermissionName(permissions.DIS_PRE_EDIT_02)"
                :isDetail="
                  withPermissionName(permissions.DIS_PRE_DL_PRE_DIS_04)
                "
                :isPdf="withPermissionName(permissions.DIS_PRE_DL_PDF_03)"
                @updateRecord="updateRecord"
                @downloadPDF="downloadPDF"
                @excelTable="excelTable"
                @scannerFile="scannerFile"
                @validate="validate"
              />
            </div>
            <!-- Dispensacion informacion de la receta -->
            <div v-show="showAction(2) || showAction(3)">
              <DispensingComponent
                ref="formDispensing"
                v-if="orderType == 1"
                :doctorList="doctorList"
                :branchOfficeList="branchOfficeList"
                :idUser="idUser"
                :idBranchOffice="branchOffice.idBranchoffice"
                @changeAction="changeAction"
                @alert="alert"
              />

              <DispensingInHospitalComponent
                ref="formInHospital"
                v-if="orderType == 2"
                :idUser="idUser"
                :idBranchOffice="branchOffice.idBranchoffice"
                :employeeList="employeeList"
                @changeAction="changeAction"
                @alert="alert"
              />
              <div class="row pt-10" v-show="idOrder != ''">
                <div class="col-12">
                  <div
                    v-bind:class="[action != 1 ? 'card border-none-b' : 'card']"
                  >
                    <v-tabs
                      class="border-top"
                      v-model="tab"
                      background-color="#263A5B"
                      centered
                      dark
                      icons-and-text
                    >
                      <v-tabs-slider />
                      <v-tab href="#1">
                        {{ $t("dispensing.dispensed") }}
                        <v-icon>mdi-equal-box</v-icon>
                      </v-tab>
                      <v-tab href="#2" @click="refreshDenied()">
                        {{ $t("dispensing.denied") }}
                        <v-icon>mdi-sim-off</v-icon>
                      </v-tab>
                    </v-tabs>

                    <AlertMessageComponent ref="alertOrder" />
                    <div class="card-body">
                      <h6>
                        <v-icon>{{
                          orderType == 1
                            ? "mdi-numeric-4-box-outline"
                            : "mdi-numeric-3-box-outline"
                        }}</v-icon>
                        {{ $t("dispensing.info_order_text") }}
                      </h6>
                    </div>
                  </div>
                  <v-tabs-items class="border-bottom" v-model="tab">
                    <v-tab-item value="1">
                      <DispensingOrderComponent
                        ref="formOrderStock"
                        :fiscalFoundList="fiscalFoundList"
                        :idUser="idUser"
                        :idBranchOffice="branchOffice.idBranchoffice"
                        @alert="alert"
                      />
                    </v-tab-item>
                    <v-tab-item value="2" eager>
                      <DispensingDeniedComponent
                        ref="formDenied"
                        :idUser="idUser"
                        :idBranchOffice="branchOffice.idBranchoffice"
                        @alert="alert"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </div>
            </div>
            <QuizDispensingModalComponent ref="showQuizDispensing" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { UtilFront, PermissionConstants } from "@/core";

import DispensingTableComponent from "@/components/dispensing/DispensingTableComponent";
import DispensingComponent from "@/components/dispensing/form/DispensingComponent";
import DispensingInHospitalComponent from "@/components/dispensing/form/DispensingInHospitalComponent";
import DispensingDeniedComponent from "@/components/dispensing/order/DispensingDeniedComponent";
import DispensingOrderComponent from "@/components/dispensing/order/DispensingOrderComponent";

import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import QuizDispensingModalComponent from "@/components/quiz/QuizDispensingModalComponent";

import {
  DISPENSING,
  DISPENSING_IN_HOSPITAL,
} from "@/core/DataTableHeadersLocale";

import {
  ExcelRequest,
  PDFRequest,
  BranchOfficeRequest,
  DoctorRequest,
  MedicalPrescriptionRequest,
  FiscalFoundRequest,
  EmployeeRequest,
} from "@/core/request";

export default {
  data() {
    return {
      title: "dispensing.dispensing",
      titleOrder: "",

      /* Pagination */
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
      },
      listDispensing: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      showTable: true,

      /* cabecera */
      dispensingHeaders: DISPENSING(),
      //dispensingValidateHeaders: DTHeaders.DISPENSING_VALIDATE,
      dispensingInHospitalHeaders: DISPENSING_IN_HOSPITAL(),
      headers: {},

      /* general */
      tab: 0, // MUESTRA LA PESTAÑA DE DISPENSAR
      action: 1,
      idUser: "",
      originId: "",
      orderType: null,
      doctorList: [],
      employeeList: [],
      fiscalFoundList: [],
      branchOfficeList: [],
      loading: null,
      permissions: PermissionConstants,

      /* (NO TOCAR) */
      shippingReason: 8, // FILTRA SOLO LOS PRODUCTOS DE TIPO DISPENSACION
    };
  },
  components: {
    DispensingTableComponent,
    DispensingComponent,
    DispensingInHospitalComponent,
    AlertMessageComponent,
    QuizDispensingModalComponent,
    DispensingDeniedComponent,
    DispensingOrderComponent,
  },
  computed: {
    ...mapState("auth", {
      branchOffice: "branchOffice",
    }),
    ...mapState("dispensing", [
      "idOrder",
      "idPrescription",
      "surveyCount",
      "isUpdate",
      "totalSurveys",
    ]),
    titleAction() {
      if (this.action == 1) {
        return this.orderType == 1
          ? this.$t("dispensing.pre_dispensed")
          : this.$t("dispensing.pre_dispensed_inhospital");
      } else if (this.action == 2) {
        return this.$t("dispensing.create") + " " + this.$t(this.titleOrder);
      } else if (this.action == 3) {
        return this.$t("dispensing.update") + " " + this.$t(this.titleOrder);
      }
      return "global.no_defined";
    },
  },
  methods: {
    /* General */
    clicQuiz() {
      this.$refs.showQuizDispensing.showQuiz(0,true);
    },
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    ...mapMutations("dispensing", {
      updatePrescription: "UPDATE_ID_PRESCRIPTION",
      updateOrder: "UPDATE_ID_ORDER",
      updateIsUpdate: "UPDATE_IS_UPDATE",
    }),
    showAction(action) {
      return this.action == action;
    },
    async changeAction(action) {
      if (action === 1) {
        this.updateAction(1);
        this.formClean();
        this.action = 1;
        //Recupero las recetas del centro de salud
        await this.findAllPrescriptionByBranchOffice(this.pagesAtributes);
      } else if (action === 2) {
        this.updateAction(2);

        //Verifico la orden después de actualizar la acción
        this.validateMedicalPrescriptionAndOrder();
      }
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    alert(success, message) {
      this.$refs.alertForm.reloadComponent(success, message);
    },
    formClean() {
      this.tab = 0;
      this.updateOrder("");
      this.updatePrescription("");
      this.updateIsUpdate(false);

      if (this.orderType == 1) this.$refs.formDispensing.reloadComponentClean();
      else if (this.orderType == 2)
        this.$refs.formInHospital.reloadComponentClean();

      this.$refs.formDenied.reloadComponentClean();
    },

    /* Doctor y personal */
    getAllDoctorByBranchOffice() {
      DoctorRequest.findAllDoctorByBranchOffice({
        idBranchOffice: this.branchOffice.idBranchoffice,
        idUser: this.idUser,
      })
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            let doctorArray = [];
            for (var [index, value] of Object.entries(data)) {
              let doctorItem = {
                text:
                  value.name +
                  " " +
                  value.lastName +
                  " | " +
                  value.professionalId,
                value: value.idDoctor,
              };
              doctorArray.push(doctorItem);
            }

            this.doctorList = doctorArray;
          } else {
            this.doctorList = [];
          }
        })
        .catch((error) => {})
        .finally(() => {});
    },
    getAllEmployeesByBranchOffice() {
      EmployeeRequest.listAllEmployees({
        idUser: this.idUser,
        idBranchOffice: this.branchOffice.idBranchoffice,
      })
        .then((response) => {
          let { success, data } = response.data.data;
          if (success) {
            let employeeArray = [];
            for (var [index, value] of Object.entries(data)) {
              let employeeItem = {
                text:
                  value.fullName +
                  " | " +
                  value.department.name +
                  " | " +
                  value.position.name,
                value: value.idEmployee,
              };
              employeeArray.push(employeeItem);
            }
            this.employeeList = employeeArray;
          } else {
            this.employeeList = [];
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    getFiscalFund() {
      //Recupero la lista de fuentes de financiamiento por sucursal y motivo de merma
      FiscalFoundRequest.listAllFiscalFundByBranchOfficeForType({
        type: this.shippingReason,
        userBranchOffice: {
          idUser: this.idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      })
        .then((response) => {
          const { success, message, data } = response.data.data;

          if (success) {
            this.fiscalFoundList = data;
          }
        })
        .catch((error) => {});
    },
    refreshDenied() {
      this.$refs.formDenied.reloadComponent(
        this.idPrescription,
        this.idUser,
        this.branchOffice.idBranchoffice
      );
    },

    /* Dispensacion */
    validateMedicalPrescriptionAndOrder() {
      //Muestro la pantalla de cargando
      this.loading = UtilFront.getLoadding();

      MedicalPrescriptionRequest.validateMedicalPrescriptionAndOrder({
        types: [this.orderType, 3],
        userBranchOffice: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: this.idUser,
        },
      })
        .then((response) => {
          let { success, message, data } = response.data.data;

          if (success) {
            //Si data es nulo, se necesita crear una nueva receta, de lo contrario, existe una en pendiente
            if (data == null) {
              this.formClean();
              this.action = 2;
            } else {
              if (this.orderType == 1)
                this.$refs.formDispensing.reloadComponentForm(data, true);
              else if (this.orderType == 2)
                this.$refs.formInHospital.reloadComponentForm(data, true);

              this.updateOrder(data.idOrder);
              this.updatePrescription(data.idPrescription);
              this.updateIsUpdate(true);

              //Cambio al formulario
              this.action = 2;
            }
          } else {
            //Alerta de error
            this.$refs.alertForm.reloadComponent("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //Elimino la pantalla de cargando
          this.loading.hide();
        });
    },
    /* Consulta las dispensaciones - Pagination */
    async findAllPrescriptionByBranchOffice(page) {
      this.loading = UtilFront.getLoadding();
      await MedicalPrescriptionRequest.findAllPrescriptionByBranchOffice({
        page,
        idBranchOffice: this.branchOffice.idBranchoffice,
        idUser: UtilFront.getDataUser().idUser,
      })
        .then((response) => {
          let { data } = response;
          this.listDispensing = data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async updateRecord(item) {
      //Muestro la pantalla de cargando
      this.loading = UtilFront.getLoadding();
      await MedicalPrescriptionRequest.getMedicalPrescriptionById({
        idPrescription: item.idPrescription,
        userBranchOffice: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: this.idUser,
        },
      })
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            if (this.orderType == 1)
              this.$refs.formDispensing.reloadComponentForm(data);
            else if (this.orderType == 2)
              this.$refs.formInHospital.reloadComponentForm(data, true);

            this.updateOrder(data.idOrder);
            this.updatePrescription(data.idPrescription);
            this.updateIsUpdate(true);

            //Cambio al formulario a update
            this.action = 3;
          } else {
            //Alerta de error
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadPDF(item) {
      let send =
        "idOrder=" + item.orderId + "&idPrescription=" + item.idPrescription;
      ExcelRequest.reportPrescription(send);
      this.$toast.success("Generando documento PDF");
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportOrdersDispensing({
        minStatus: 2,
        type: this.orderType,
        userBranchOffice: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: this.idUser,
        },
      })
        .then((response) => {
          ExcelRequest.download(response, "dispensacion.xlsx");
        })
        .finally(() => {
          //this.loadingScren.hide();
        });
    },
    scannerFile(item) {
      MedicalPrescriptionRequest.downloadPrescription({
        prescriptionKey: item.prescriptionKey,
        medicalPrescriptionPath: item.medicalPrescriptionPath,
        userBranchOffice: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: this.idUser,
        },
      })
        .then((response) => {
          let { success, message, data } = response.data.data;

          if (success) {
            PDFRequest.downloadBase64PDF(data, message);
            this.$toast.success("Descargando receta escaneada.");
          } else {
            this.$toast.error(message);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    validate(item) {
      MedicalPrescriptionRequest.updateValidated({
        idPrescription: item.idPrescription,
        userBranchOffice: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: this.idUser,
        },
      })
        .then((response) => {
          let { success, message } = response.data.data;

          if (success) {
            const title = item.validate ? "habilitada" : "deshabilitada";
            this.$toast.success(
              "La receta fisica fue " + title + " correctamente."
            );
          } else {
            this.$toast.error(message);
            item.validate = !item.validate;
          }
        })
        .catch((error) => {
          this.$toast.error(error);
          item.validate = !item.validate;
        });
    },
  },
  watch: {
    surveyCount: function (val) {
      if (val == this.totalSurveys)
        this.$refs.showQuizDispensing.showQuiz(this.idPrescription);
    },
    async branchOffice() {
      this.showTable = false;
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
      };
      this.$nextTick(() => {
        this.showTable = true;
      });
      this.getAllDoctorByBranchOffice();
      this.getFiscalFund();
    },
    "$i18n.locale": function () {
      this.dispensingHeaders = DISPENSING();
      this.dispensingInHospitalHeaders = DISPENSING_IN_HOSPITAL();
      this.headers = this.dispensingHeaders;
    },
    action(){
      this.updateAction(this.action)
    }
  },
  created() {
    let infoUser = UtilFront.getDataUser();
    //Recupero las unidades medicas
    this.branchOfficeList = BranchOfficeRequest.listAllByTypeKey();
    this.titleOrder = "dispensación";
    this.headers = this.dispensingHeaders;
    this.idUser = infoUser.idUser;
    this.updateAction(1);
    this.orderType = 1;

    this.getAllDoctorByBranchOffice();
    //Recupero la fuente de financiamiento
    this.getFiscalFund();
  },
};
</script>
